import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: "8px",
    minWidth: "400px",
    [theme.breakpoints.down("xs")] : {
      minWidth: "300px"
    }
  },
  farmSelectDropdown: {
    [theme.breakpoints.down("xs")] : {
      maxWidth: "200px"
    }
  },
  scrollPaper: {
    marginTop: "10vh",
    alignItems: "baseline"
  }
}));

export default useStyles;