import moment from "moment";
import { BatchDeliveryDto, FeedInfoDto, FeedInfoGrainDto, FeedInfoRowDto, FeedTemplateDto, FeedTemplateGrainDto, FeedTemplateRowDto,
  HallInfoDto, HallTemplateDto, HealthDiseaseDto, HealthInfoDto, HealthMedicationDto, PoultryDiaryDto } from "../models/ApiSchema";

export const numberEmptyToNull = (value: any, originalValue: any) => {
  if (typeof originalValue === 'string') {
    return originalValue === '' ? null : Number(originalValue.replace(/,/, '.'));
  }
  return value;
}

export const numberEmptyToZero = (value: any, originalValue: any) => {
  if (typeof originalValue === 'string') {
    return originalValue === '' ? 0 : Number(originalValue.replace(/,/, '.'));
  }
  return value;
}

export const dateOnly = (value: any, originalValue: any) => {
  if (!originalValue) return value;
  let date = moment(originalValue);
  if (date.isValid()) {
    // HACK: reset date to midday local time for date-pickers
    return moment(date).startOf('day').hours(12).toDate();
  }
  return value;
}

export const localizeDate = (date: Date) => {
  return new Date(date.getTime() - date.getTimezoneOffset()*60000);
}

export const truncateSeconds = (value: any, originalValue: any) => {
  if (!originalValue) return value;
  let date = moment(originalValue);
  if (date.isValid()) {
    return date.second(0).millisecond(0).toDate();
  }
  return value;
}

const _nullToEmptyString = (val: any | null | undefined) => val == null || val === undefined ? "" : val;

export const batchDeliveryFormData = (data: BatchDeliveryDto) => {
  data.sentQuantity = _nullToEmptyString(data.sentQuantity);
  data.transportMortality = _nullToEmptyString(data.transportMortality);
  data.parentFlocks = data.parentFlocks || [];
  data.parentFlockAvgAge = _nullToEmptyString(data.parentFlockAvgAge);
  data.breed = _nullToEmptyString(data.breed);
  data.hatchery = _nullToEmptyString(data.hatchery);
  data.flockType = _nullToEmptyString(data.flockType);
  data.deliveryNotes = _nullToEmptyString(data.deliveryNotes);
  return data;
}

export const feedRowFormData = (data: FeedTemplateRowDto | FeedInfoRowDto) => {
  data.name = _nullToEmptyString(data.name);
  data.coccidiostat = _nullToEmptyString(data.coccidiostat);
  data.co2 = _nullToEmptyString(data.co2);
  data.withholdingPeriod = _nullToEmptyString(data.withholdingPeriod);
  return data;
}

export const feedGrainFormData = (data: FeedTemplateGrainDto | FeedInfoGrainDto) => {
  data.strain = _nullToEmptyString(data.strain);
  data.proteinPercent = _nullToEmptyString(data.proteinPercent);
  data.hectoLiterWeight = _nullToEmptyString(data.hectoLiterWeight);
  data.treshingYear = _nullToEmptyString(data.treshingYear);
  data.percentOfTotal = _nullToEmptyString(data.percentOfTotal);
  return data;
}

export const feedFormData = (data: FeedTemplateDto | FeedInfoDto) => {
  data.feedFactory = _nullToEmptyString(data.feedFactory);
  data.feedProgram = _nullToEmptyString(data.feedProgram);
  data.startFeeds = data.startFeeds?.map(row => feedRowFormData(row));
  data.grow1Feeds = data.grow1Feeds?.map(row => feedRowFormData(row));
  data.grow2Feeds = data.grow2Feeds?.map(row => feedRowFormData(row));
  data.endFeeds = data.endFeeds?.map(row => feedRowFormData(row));
  data.grains = data.grains?.map(row => feedGrainFormData(row));
  return data;
}

export const hallFormData = (data: HallTemplateDto | HallInfoDto) => {
  data.detergent = _nullToEmptyString(data.detergent);
  data.detergentAmount = _nullToEmptyString(data.detergentAmount);
  data.disinfectant = _nullToEmptyString(data.disinfectant);
  data.disinfectantAmount = _nullToEmptyString(data.disinfectantAmount);
  data.nippleLineDetergent = _nullToEmptyString(data.nippleLineDetergent);
  data.nippleLineDetergentAmount = _nullToEmptyString(data.nippleLineDetergentAmount);
  data.nippleLineDisinfectant = _nullToEmptyString(data.nippleLineDisinfectant);
  data.nippleLineDisinfectantAmount = _nullToEmptyString(data.nippleLineDisinfectantAmount);
  data.feedSiloDetergent = _nullToEmptyString(data.feedSiloDetergent);
  data.feedSiloDetergentAmount = _nullToEmptyString(data.feedSiloDetergentAmount);
  data.litterMaterial = _nullToEmptyString(data.litterMaterial);
  data.litterSupplier = _nullToEmptyString(data.litterSupplier);
  data.notes = _nullToEmptyString(data.notes);
  data.visitors = _nullToEmptyString(data.visitors);
  data.pesticide = _nullToEmptyString(data.pesticide);
  return data;
}

export const diaryFormData = (data: PoultryDiaryDto) => {
  data.feedConsumptionKg = _nullToEmptyString(data.feedConsumptionKg);
  data.grainConsumptionKg = _nullToEmptyString(data.grainConsumptionKg);
  data.waterConsumptionLiter = _nullToEmptyString(data.waterConsumptionLiter);
  data.liveWeightGrams = _nullToEmptyString(data.liveWeightGrams);
  data.temperature = _nullToEmptyString(data.temperature);
  data.humidity = _nullToEmptyString(data.humidity);
  data.co2 = _nullToEmptyString(data.co2);
  data.otherSupplements = _nullToEmptyString(data.otherSupplements);
  data.notes = _nullToEmptyString(data.notes);
  return data;
}

export const diseaseFormData = (data: HealthDiseaseDto) => {
  data.samplesTaken = _nullToEmptyString(data.samplesTaken);
  data.veterinarianDiagnosis = _nullToEmptyString(data.veterinarianDiagnosis);
  data.foodAgencyDiagnosis = _nullToEmptyString(data.foodAgencyDiagnosis);
  return data;
}

export const medicationFormData = (data: HealthMedicationDto) => {
  data.medicine = _nullToEmptyString(data.medicine);
  data.withholdingPeriod = _nullToEmptyString(data.withholdingPeriod);
  return data;
}

export const healthFormData = (data: HealthInfoDto) => {
  data.noAnimalProtectionRestrictions = data.noAnimalProtectionRestrictions || false;
  data.noFoodSafetyIssuesDetected = data.noFoodSafetyIssuesDetected || false;
  data.healthAnomaliesDetected = data.healthAnomaliesDetected || false;
  data.healthAnomalies = _nullToEmptyString(data.healthAnomalies);
  data.noMedicationsUsed = data.noMedicationsUsed || false;
  data.diseases = data.diseases?.map(row => diseaseFormData(row));
  data.medications = data.medications?.map(row => medicationFormData(row));
  // @ts-expect-error
  data.salmonellaSamplePositive = data.salmonellaSamplePositive == null ? "" : data.salmonellaSamplePositive ? "true" : "false";
  return data;
}
